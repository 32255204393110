﻿/*
 *  Document   : _buttons.scss
 *  Author     : RedStar Template
 *  Description: This scss file for button style classes
 */
 @import "../_variables.scss";

.example-button-row button,
.example-button-row a {
  margin-right: 8px;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.btn-primary {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:hover {
  color: #fff;
  background-color: $secondary-color;
  border-color: $secondary-color;
}

.mat-primary:not(.mat-form-field):not(.mat-datepicker-content):not(.mat-select-panel):not(.disabled) {
  color: #ffffff !important;
  background-color: $primary-color !important;
}

.mat-secondary:not(.mat-form-field):not(.mat-datepicker-content):not(.mat-select-panel):not(.disabled) {
  color: #ffffff !important;
  background-color: $secondary-color !important;
}

.mat-info:not(.mat-form-field):not(.mat-datepicker-content):not(.mat-select-panel):not(.disabled) {
  color: #ffffff !important;
  background-color: #3f51b5 !important;
}

.mat-primary[disabled],
.mat-primary.disabled,
.mat-secondary[disabled],
.mat-secondary.disabled,
.mat-info[disabled],
.mat-info.disabled {
  background-color: rgba(0,0,0,.12) !important;
}