/*
 *  Document   : _variables.scss
 *  Author     : RedStar Template
 *  Description: This scss file for declare variable for colors
 */
$colors: ("red": #f44336,
  "pink": #e91e63,
  "purple": #9c27b0,
  "deep-purple": #673ab7,
  "indigo": #3f51b5,
  "blue": #2196f3,
  "light-blue": #03a9f4,
  "cyan": #00bcd4,
  "teal": #009688,
  "green": #4caf50,
  "light-green": #8bc34a,
  "lime": #cddc39,
  "yellow": #ffe821,
  "amber": #ffc107,
  "orange": #ff9800,
  "deep-orange": #ff5722,
  "brown": #795548,
  "grey": #9e9e9e,
  "blue-grey": #607d8b,
  "black": #000000,
  "white": #ffffff,
  "dark-gray": #888888) !default;

$linear-colors: ("green": linear-gradient(45deg, #9ce89d, #cdfa7e),
  "orange": linear-gradient(135deg, #ffc480, #ff763b),
  "cyan": linear-gradient(45deg, #72c2ff, #86f0ff),
  "red": linear-gradient(316deg, #fc5286, #fbaaa2),
  "purple": linear-gradient(230deg, #759bff, #843cf6),
  "purple-dark": linear-gradient(45deg, #a52dd8, #e29bf1));

//Fonts Family
$navbar-font-family: "Poppins";
$sidebar-font-family: "Poppins";
