.ph-item {
  direction: ltr;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 15px 15px 15px;
  overflow: hidden;
  margin-bottom: 30px;
  background-color: #fff;
  border: 1px solid #e6e5e5;
  border-radius: 2px;
}

.ph-item, .ph-item *,
.ph-item ::after, .ph-item ::before {
  box-sizing: border-box;
}

.ph-item::before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 500%;
  margin-left: -250%;
  animation: phAnimation 0.8s linear infinite;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%;
}

.ph-item > * {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  padding-right: 15px;
  padding-left: 15px;
}

.ph-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7.5px;
}

.ph-row div {
  height: 10px;
  margin-bottom: 7.5px;
  background-color: #ced4da;
}

.ph-row .big,
.ph-row.big div {
  height: 20px;
  margin-bottom: 15px;
}

.ph-row .empty {
  background-color: rgba(255, 255, 255, 0);
}

.ph-col-2 {
  flex: 0 0 16.66666667%;
}

.ph-col-4 {
  flex: 0 0 33.33333333%;
}

.ph-col-6 {
  flex: 0 0 50%;
}

.ph-col-8 {
  flex: 0 0 66.66666667%;
}

.ph-col-10 {
  flex: 0 0 83.33333333%;
}

.ph-col-12 {
  flex: 0 0 100%;
}

.ph-avatar {
  position: relative;
  width: 100%;
  min-width: 60px;
  background-color: #ced4da;
  margin-bottom: 15px;
  border-radius: 50%;
  overflow: hidden;
}

.ph-avatar::before {
  content: " ";
  display: block;
  padding-top: 100%;
}

.ph-picture {
  width: 100%;
  height: 120px;
  background-color: #ced4da;
  margin-bottom: 15px;
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}

.ph-item {
  background: none !important;
  border: 0 !important;
}

/* You can add global styles to this file, and also import other style files */
section.content {
  margin: 30px 15px 0 276px;
}

.pointer {
  cursor: pointer;
}

.no-borde {
  border: none !important;
}

.text-center .datatable-header-cell-template-wrap {
  text-align: center !important;
}

.bolder {
  font-weight: bolder !important;
}

.mat-input-element:disabled {
  color: #000000c2 !important;
}

.select-trie {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: inherit;
}

.search-team:focus {
  width: 220px !important;
}

.color-white {
  color: white;
}

.all-height {
  height: 100%;
}

.all-width {
  width: 100% !important;
}

.hoverLoc:hover button {
  background-color: #4fa745;
}

.hoverLoc:hover span {
  display: initial !important;
}

.mat-form-field-outline-start,
.mat-form-field-outline-gap,
.mat-form-field-outline-end {
  color: rgba(0, 0, 0, 0.459) !important;
}

.inv .mat-form-field-outline-start,
.inv .mat-form-field-outline-gap,
.inv .mat-form-field-outline-end {
  color: #9933FF !important;
  border-width: 2px !important;
}

.zonage .mat-chip-list-wrapper {
  margin-bottom: 0.5%;
}

.pick-loc .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.pick-loc .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #f15381 !important;
}

.pick-loc .mat-checkbox-disabled .mat-checkbox-background,
.pick-loc .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #979596ef !important;
}

.new-immo .mat-form-field-outline-start,
.new-immo .mat-form-field-outline-gap,
.new-immo .mat-form-field-outline-end {
  color: #28a745 !important;
}

.mat-chip-localite {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  margin: 4px;
  height: 1px;
  line-height: 1px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  padding: 15px 10px !important;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.mat-chip-localite .btn-actions {
  position: absolute;
  right: -20px;
  top: -2px;
}

.mat-chip.active,
.mat-chip-localite.active {
  background-color: #ef7711 !important;
  color: #ffffff  !important;
  opacity: 1 !important;
}

.mat-chip.active .mat-checkbox-frame,
.mat-chip-localite.active .mat-checkbox-frame {
  border-color: #ffffff;
}

.mat-chip.active .mat-checkbox-layout .mat-checkbox-label,
.mat-chip-localite.active .mat-checkbox-layout .mat-checkbox-label {
  color: #ffffff !important;
}

.mat-chip.active .mat-icon,
.mat-chip-localite.active .mat-icon {
  color: #ffffff !important;
}

.breadcrumb > li + li:before {
  display: none;
}

.cal-events .cal-event {
  display: none !important;
}

.ngxTableHeader {
  border-color: #ef7711;
  background: radial-gradient(#EF7711, #774007);
  border-radius: 10px 10px 0 0;
}

.ngxTableHeader select.form-control {
  background-color: transparent !important;
  color: #ffffff;
}

.ngxTableHeader select.form-control option {
  color: #000000;
}

.ngxTableHeader .mat-mini-fab.mat-primary {
  background-color: #ef7711;
  border: 1.5px solid #ffffff;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
  font-size: 1.2rem !important;
  text-transform: lowercase;
}

.ngx-datatable.material .datatable-header .datatable-header-cell:first-letter {
  text-transform: uppercase;
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: rgba(239, 119, 40, 0.6);
  border-radius: 10px;
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-body-cell-label, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .material-icons, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group .datatable-body-cell-label, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group .material-icons {
  color: #ffffff;
}

.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #ef7711;
}

.ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  background-color: #ef7728;
  color: #ffffff;
}

.btn-orange {
  background-color: #ef7711;
  border: 1.5px solid #ffffff;
  color: #ffffff;
  border-radius: 5px;
}

.btn-orange-gradient {
  background: radial-gradient(#EF7711, #774007);
  border: 1.5px solid #ffffff;
  color: #ffffff;
  border-radius: 5px;
}

.btn-40-rounded {
  border-radius: 50% !important;
  width: 40px;
  height: 40px;
  padding: 0;
}

#container-progess-bar {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  top: -25%;
}

#container-progess-bar .circle {
  position: relative;
  top: 5px;
  left: 5px;
  text-align: center;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  background-color: black;
}

#container-progess-bar .border {
  position: relative;
  text-align: center;
  width: 210px;
  height: 210px;
  border-radius: 100%;
  top: 50%;
  background-color: #00a651;
}

#container-progess-bar .needle {
  position: absolute;
  background-color: #f0566f;
  height: 100%;
  width: 0.5%;
  left: 50%;
}

#container-progess-bar .needle:before {
  content: "";
  position: absolute;
  top: 0px;
  left: -10px;
  width: 0px;
  height: 0px;
  border-top: 10px solid transparent;
  border-right: 20px solid #f0566f;
  border-bottom: 10px solid transparent;
  -webkit-transform: rotate(90deg);
}

.card-form-affect-localite {
  margin-bottom: 0 !important;
}

.card-form-affect-localite .card-body {
  padding-right: 0 !important;
}

.mat-success {
  background-color: #66BB6A;
  color: #fff;
}

.sidebar {
  transition: all 0.5s;
  font-family: "Poppins";
  background: #ffffff;
  width: 278px;
  overflow: hidden;
  display: inline-block;
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 999 !important;
}

.circle-progress-loader {
  background: rgba(255, 255, 255, 0.53);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.circle-progress-loader div.loader {
  height: 500px;
  margin-top: 14%;
  margin-left: 8%;
}

.p-calendar {
  max-height: 30px !important;
  max-width: 150px !important;
}

.p-calendar .p-button {
  background: #ffffff !important;
  color: #ef7728 !important;
  border-color: #ffffff !important;
}

.p-calendar .p-inputtext {
  background: inherit;
  color: #fefefe;
}

/************************************************************************************************/
.styled-checkbox {
  position: absolute;
  opacity: 0;
}

.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  padding: 0;
  color: #ffffff;
}

.styled-checkbox + label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px !important;
  height: 20px !important;
  background: white;
  border-radius: 5px;
}

.styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked + label:before {
  background: #0275d8;
}

.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}

.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}

.styled-checkbox:disabled:checked + label:before {
  background: #0275d8;
}

.styled-checkbox:checked + label:after {
  content: '';
  position: absolute;
  left: 4px;
  top: 9px;
  background: white;
  width: 3px;
  height: 3px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

/* ************************************************************************************************** */
.dialog-data-zones {
  background: #ffffff;
}

.dialog-data-zones table {
  border-collapse: separate;
  border-spacing: 0 0.5em;
}

.dialog-data-zones .p-datatable .p-datatable-thead > tr > th {
  color: #ffffff !important;
  background: #f47f38 !important;
}

.dialog-data-zones .p-datatable .p-datatable-thead > tr > th,
.dialog-data-zones .p-datatable .p-datatable-tbody > tr > td {
  position: relative;
  font-size: 1.1rem;
  padding: 0.4rem 1rem;
}

.dialog-data-zones .p-datatable .p-datatable-thead > tr > th::after,
.dialog-data-zones .p-datatable .p-datatable-tbody > tr > td::after {
  content: '|' !important;
  color: #9b9da0 !important;
  position: absolute;
  right: 0;
  font-size: 12px;
}

.dialog-data-zones .p-datatable .p-datatable-thead > tr > th:last-child::after,
.dialog-data-zones .p-datatable .p-datatable-tbody > tr > td:last-child::after {
  display: none;
}

.dialog-data-zones .p-datatable .p-datatable-tbody > tr {
  background: #ffffff !important;
}

.dialog-data-zones .p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: #d1d2d4 !important;
}

.highlight-notification,
.highlight-notification:hover {
  background-color: #c4f1d8 !important;
  border-top: 1px solid #ffffff !important;
  border-bottom: 1px solid #ffffff !important;
}

.mat-menu-item.active-menu {
  background: #ef7711 !important;
  color: #ffffff !important;
}

.input-update-end-libelle {
  border-width: 2px;
  height: 45px;
  font-size: 16px !important;
  font-weight: bold;
}
